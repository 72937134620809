.project-data {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@media only screen and (max-width: 899px) {
    .project-data {
        flex-direction: column;
        align-items: flex-start;
    }
}