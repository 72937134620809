.url-container {
    flex: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 40px;
}

@media only screen and (max-width: 899px) {
    .url-container {
        align-items: flex-start;
    }
}