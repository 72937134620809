#navbar {
    position: fixed;
    width: 100%;
    top: 0;
}

#banner {
    position: fixed;
    width: 100%;
    top: 60px;
}

#content {
    margin-top: 300px;
    z-index: 2;
    position: relative;
    background-color: var(--section-bg-color);
}

/* I can't think of a cleaner way to fix the bad margin-top */
@media only screen and (max-width: 1200px) {
    #content {
        margin-top: 350px;
    }
}

@media only screen and (max-width: 1000px) {
    #content {
        margin-top: 400px;
    }
}

@media only screen and (max-width: 768px) {
    #content {
        margin-top: 450px;
    }
}