.project-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.project-list {
    width: 60%;
    margin: 30px auto; /* centre */
}

@media only screen and (max-width: 1200px) {
    .project-list {
        width: 90%;
    }
}

@media only screen and (max-width: 899px) {
    .project-list {
        width: 95%;
    }
}