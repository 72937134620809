.subproject-container {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

.subproject-title, .subproject-description {
    align-content: center;
}

.subproject-title {
    flex: 25%;
    margin-right: 10px;
    text-align: center;
}

.subproject-description {
    flex: 75%;
    margin-left: 10px;
}