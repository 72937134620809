.date-container {
    --date-text-color: #7E7E7E;

    padding-right: 20px;
}

.start-date, .end-date, .date-separator {
    color: var(--date-text-color);
    font-style: italic;
    padding: 5px;
}