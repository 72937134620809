#banner {
    --banner-bg-color: #DFE4E8;
    --banner-dot-color: #C0C0C0;
    
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    box-sizing: border-box;
    background-color: var(--banner-bg-color);
    background-size: 20px 20px;
    background-image: radial-gradient(circle, var(--banner-dot-color) 0.5px, transparent 10%);
}

.banner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 20px 20px;
}

#title {
    margin-top: 10px;
}

#subtitle {
    padding-left: 20px;
}