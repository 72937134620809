.project-url-link {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: center;
}

.project-url-link a {
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    font-weight: 400;
    color: var(--text-highlight-color);
}