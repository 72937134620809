#footer {
    width: 100%;
    background-color: var(--primary-footer-bg-color);
}

.footer-container {
    font-size: 12pt; /* slightly smaller */

    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.footer-link-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px;
}

.footer-link {
    padding: 5px 0;
}

.footer-link a {
    text-decoration: none;
    color: var(--text-highlight-color)
}

.footer-link-column-heading {
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }

    .footer-link-column {
        padding: 5px 5%;
    }

    .footer-link {
        padding: 2px;
    }
}