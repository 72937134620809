.image-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.certification-container {
    display: flex;
    width: 60%;
}

.certification {
    flex: 1;
    width: 100%;
    height: auto;
    margin: auto;
    object-fit: contain;
    border-radius: 10px;
}