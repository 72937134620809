.even-section {
    background-color: var(--primary-bg-color);
}

.odd-section {
    background-color: var(--secondary-bg-color);
}

#about {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-section {
    width: 100%;
    min-height: 300px;
}

.dynamic-content {
    display: flex;
    align-items: center;
    width: 60%;
    margin: 0 auto; /* This centers the div */
}

.about-section-content, .about-section-experiences {
    justify-content: space-between;

}

.about-section-content:first-child {
    padding-top: 50px;
}

.about-text, .about-frame-container {
    display: flex;
    padding: 0 20px 0 20px;
    flex-flow: column nowrap;
    align-content: center;
}

.about-text {
    flex: 70%; /* take up 70% of parent's flexbox */
}

.about-frame-container {
    flex: 40%;
    align-content: center;
    justify-content: center;
}

.about-section-heading {
    text-align: center;
}

.about-section h2 {
    color: var(--text-highlight-color);
}

.about-section-experiences {
    display: flex;
    width: 60%;
    align-items: center;
    margin: 0 auto;
}

.about-section-experiences {
    flex-direction: column;
}

/* Smaller screens should have slightly different layout */
@media only screen and (max-width: 1200px) {
    .dynamic-content {
        width: 90%;
    }
}

@media only screen and (max-width: 899px) {
    .dynamic-content {
        width: 95%;
        flex-direction: column;
        justify-content: flex-start; /* so they are more compact */
    }

    /*
     * Because the even sections have the div holding the heading
     * for the section on the other side, but the heading should go
     * on top when stacked.
     */
    .even-section .about-section-content {
        flex-direction: column-reverse;
    }
}