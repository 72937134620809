#whoami-frame {
    border: 1px solid #CCC;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    margin: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-card-image {
    display: inline;
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
}

.link-icon-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.social-link {
    text-decoration: none;
    margin: 5px 5px;
}

.profile-card-email-container {
    text-align: center;
}