.project-card {
    border: 1px #18181810 solid;
    background-color: var(--project-card-bg-color);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
}

.project-card-content {
    padding: 0 30px 0 30px;
    margin-bottom: 20px;
}

.project-card:hover {
    z-index: 1;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.project-card-heading a {
    color: var(--text-highlight-color);
    text-decoration: none;
}

.project-card-trailer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px #000 dotted;
    box-sizing: border-box;
}

.project-card-trailer-link {
    border-radius: 5px;

    padding: 15px;
    margin: 20px;
    background-color: var(--btn-highlight-color);
}

.project-card-trailer-link-button {
    font-size: 16pt;
    
    border: none;

    text-align: center;
    width: 100%;
    height: 100%;

    color: #FFF;
    background-color: var(--btn-highlight-color);


    cursor: pointer;
}