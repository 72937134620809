.details-container, .overview-container {
    --details-bg-color: #F3F3F3;

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.details-content, .overview-content {
    margin: 30px;
    padding: 30px;
    border-radius: 10px;
}

.details-content {
    background-color: var(--details-bg-color);
    width: 60%;
}

.overview-content {
    width: 60%;
}

.toggle-page-prompt {
    color: var(--text-highlight-color);
}

.toggle-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 0 10vh 0;
    background-color: var(--secondary-footer-bg-color);
    width: 100%;
}

.toggle-page-button {
    display: inline-block;
    padding: 15px 30px;
    margin: 0;
    background-color: var(--btn-highlight-color);
    color: #FFF;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 899px) {
    .overview-content, .details-content {
        width: 90%;
    }
}