#navbar {
  font-family: Hack, monospace;
  font-size: 20px;
  height: 60px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0;
  background-color: var(--navbar-bg-color);
  z-index: 1000; /* stay above everything else */
}

#navbar li > a {
  display: block;
  color: var(--text-highlight-color);
  font-family: monospace;
  text-align: center;
  padding: 20px 10px;
  text-decoration: none;
}