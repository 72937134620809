.tags-container {
    font-weight: 400;
    flex: 80%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
